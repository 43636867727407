import React, { useState } from "react";
import { Link } from "gatsby";
import ResourceTag from "./resource-tag";

const Teasers = ({
  items,
  classNameSection = "py-12 lg:py-32",
  classNameGrid = "lg:grid-cols-3",
  classNameGridContainer = "container max-w-[1200px]",
}) => {
  const [selectedVideo, setSelectedVideo] = useState(false);

  const click = (e) => {
    const item = items.find((item) => item.id === e.target.id);
    if (item) {
      openModal(item);
    }
  };

  const openModal = (item) => {
    setSelectedVideo(item.relationships.video.localFile.publicURL);
  };

  const closeModal = () => {
    setSelectedVideo(false);
  };

  const cards = items.map((card) => {
    return card.type === "Videos" ? { ...card, clickHnadler: click } : card;
  });

  if (items.length === 0) return false;
  //console.log(cards)
  return (
    <section className={classNameSection}>
      <VideoModal video={selectedVideo} close={closeModal} />
      <div className={classNameGridContainer}>
        <div
          className={
            "grid grid-cols-1  gap-x-12 gap-y-4 lg:gap-y-12 " + classNameGrid
          }
        >
          {cards.map((item, index) => (
            <Card item={item} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

const Card = ({ item }) => {

  return item.type !== "Videos" ? (
    <CardRegular item={item} />
  ) : (
    <CardVideo item={item} />
  );
};

const CardBase = () => {};

CardBase.Container = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

CardBase.Body = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

CardBase.Title = ({ className, children }) => {
  return <h3 className={className}>{children}</h3>;
};

CardBase.Teaser = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

CardBase.Button = ({ text = "", className = "", ...props }) => {
  return (
    <button className={className} {...props}>
      {text}
    </button>
  );
};

CardBase.BgImage = ({ className = "", style = {} }) => {
  return <div className={className} style={style} />;
};

const CardRegular = ({ item }) => {
  const path = item?.teaser_link?.uri ?  item.teaser_link.uri.replace("internal:", '') :  item?.path?.alias + "/";
  
  const style = {};
  if (item?.relationships?.image?.localFile?.publicURL) {
    style.backgroundImage = `url(${encodeURI(
      item?.relationships?.image?.localFile?.publicURL
    )})`;
  }
  return (
    <Link to={path}>
      <CardBase.Container className="-h-fit border -hover:border-black rounded-[10px]  shadow-xl	  -relative flex flex-col -lg:h-[250px]">
        <CardBase.BgImage
          className="h-[218px] border bg-cover bg-[#F5F9FF] rounded-t-[10px]"
          style={style}
        />
        <ResourceTag type={item.type} item={item} />
        <CardBase.Body className="px-6 py-4">
          <CardBase.Title className="text-[24px] leading-[32px] font-bold	pb-3 h-[132px] text-ellipsis overflow-hidden ">
            <div className="hover:text-blue">{item.title}</div>
          </CardBase.Title>
          <CardBase.Teaser className="h-[100px]  text-base  text-gray-700 grow text-ellipsis overflow-hidden hover:text-blue">
            {item.teaser}
          </CardBase.Teaser>
        </CardBase.Body>
      </CardBase.Container>
    </Link>
  );
};

const CardVideo = ({ item }) => {
  //const path = item?.path?.alias + "/";
  const style = {};
  if (item?.relationships?.image?.localFile?.publicURL) {
    style.backgroundImage = `url(${encodeURI(
      item?.relationships?.image?.localFile?.publicURL
    )})`;
  }
  return (
    <CardBase.Container className="-h-fit border -hover:border-black rounded-[10px]  shadow-xl	  -relative flex flex-col -lg:h-[250px]">
      <CardBase.Button
        className={`h-[218px] border bg-cover bg-[#F5F9FF] cursor-pointer resource-video-play resource-video-play-${item.id}`}
        style={style}
        onClick={item.clickHnadler}
        id={item.id}
      />
      <ResourceTag type={item.type} item={item} />
      <CardBase.Body className="px-6 py-4">
        <CardBase.Title className="text-[24px] leading-[32px] font-bold	pb-3 h-[132px] text-ellipsis overflow-hidden ">
          <CardBase.Button
            id={item.id}
            className="hover:text-blue text-left"
            onClick={item.clickHnadler}
            text={item.title}
          />
        </CardBase.Title>
      </CardBase.Body>
    </CardBase.Container>
  );
};

const VideoModal = ({ video, close, children }) => {
  if (!video) return false;

  const show = video !== false;

  return (
    <div
      className={
        "modal opacity-0  fixed w-full h-full top-0  left-0 flex items-center justify-center z-50 " +
        (show ? " block opacity-100" : " hidden opacity-0")
      }
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={close}
        onKeyDown={close}
        role="presentation"
      ></div>

      <div
        className="bg-white w-11/12 lg:max-w-[840px] lg:max-h-[500px] mx-auto rounded "
        style={{ zIndex: "150" }}
      >
        <div className="modal-content  ">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video controls _width="600">
            <source src={video} type="video/mp4" />
          </video>
          {children}
        </div>
      </div>
    </div>
  );
};

export { Teasers, Card, VideoModal };
