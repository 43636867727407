import React, { /*useState,*/ useRef } from "react"
//import TestimonialCardHp from "./cards/testimonial-hp"
//import TestimonialCardMobileHp from "./cards/testimonial-hp-mobile"
import useTestimonialsBlock from "../hooks/useTestimonials2Block"
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from 'swiper';
import Image from "./Image"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



const TestimonialsSlideshow = ({ 
  title, 
  isMobile, 
  isHomepage, 
  className="bg-[#F2F7FE] lg:bg-[url('/background/testimonial-quate.png')]" ,
  classNameSection="lg:px-20" ,
  classNameTitle="text-4xl lg:text-[48px] text-center ",
  classNameCard="lg:w-3/4"
}) => {

  const swiper = useRef(null)

  const items = useTestimonialsBlock()

  //const [activeSlide, setActiveSlide] = useState(0);

  const testimonials = items.filter(e => e.label)

  if (testimonials.length === 0) return false


  return (
    <section className={"bg-no-repeat bg-center lg:h-full py-16 " + className}>
      <div className={"container lg:h-full space-y-4 lg:space-y-14 " + classNameSection}>

        {title !== '' && 
          <h2 
            className={"font-bold " + classNameTitle } 
            dangerouslySetInnerHTML={{ __html: title }} 
          />
        }


        <div className="">
          <Swiper
            ref={swiper}
            modules={isMobile ? [Pagination, Navigation] : [Navigation]}
            className={isMobile ? ' ' : "  lg:w-full swiper-on-front-page"}
            //spaceBetween={isMobile ? 10 : 50}
            slidesPerView={1}
            navigation={isMobile ? false : true}
            pagination={isMobile ? { clickable: true } : {}}
            //onSlideChange={(swiper) => { setActiveSlide(swiper.activeIndex) }}
          //onSwiper={(swiper) => console.log(swiper)}
          >
            {testimonials.map((props, i) => (
              <SwiperSlide className=" " key={i}>
                <Card {...props} key={i} className={classNameCard} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* <div className="lg:hidden pt-6 pb-6">
          <Swiper
            modules={[Pagination]}
            spaceBetween={30}
            slidesPerView={1}
            navigation={false}
            pagination={{ clickable: true }}
            className=""
          >
            {testimonials.map((props, i) => (
              <SwiperSlide className="mb-14 mt-6  " key={i}>
                <TestimonialCardMobileHp {...props} key={i} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div> */}

      </div>
    </section>
  )
}



const Card = ({ label, slug, descriptionHtml, image, relationships, company, logo, className = 'lg:w-3/4',  ...props }) => {

  //console.log(descriptionHtml)

  return (
    <div className={"mx-auto " + className} >

      <div className="lg:p-4">

        <div className="flex flex-row items-start">

          <div className="my-8 lg:m-8">

            <img
              src={relationships.logo.localFile.publicURL}
              height={logo.height * 0.75}
              width={logo.width * 0.75}
              alt={logo.alt}
              className="block mb-6"
            />

            <div className="text-base text-dark testimonial-desc" dangerouslySetInnerHTML={{ __html: descriptionHtml }} />

            <div className="flex mt-8 items-center flex-row">
              <div>
                {/* <img src={relationships.image.localFile.url} alt={image.alt} /> */}
                <Image image={relationships.image} alt={image.alt} className="" width={image.width} height={image.height} loading="lazy" />
              </div>
              <div>
                <div className="text-sm leading-6	-mt-1 ml-4">
                  <div className="font-bold">{label}</div>
                </div>
                <div className="text-sm leading-6	-mt-1 ml-4">
                  <div>{slug}</div>
                </div>
                
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

  )
}

/*
const TestimonialCardMobileHp = ({ label, slug, descriptionHtml, image, relationships, company, logo, ...props }) => {

  return (

    <div className=" mx-auto  relative mb-4">

      <div className="bg-[#012965] -z-50 w-full h-full  absolute top-6 left-1 rounded-[14px] border border-blue" />

      <div className="bg-[#F9FBFF] rounded-[14px] shadow-lg top-[14px] left-[14px] relative border">

        <div className="flex flex-col items-start p-6">

          <div className="m-1 mb-4 h-16">
            <Image image={relationships.logo} alt={logo.alt} width={logo.width} height={logo.height} className="" />
          </div>
          <div className="text-base text-dark testimonial-desc" dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
          <div className="flex flex-row mt-8 -items-center space-x-4">
            <div>
              <Image image={relationships.image} alt={image.alt} className="" width={image.width} height={image.height} loading="lazy" />
            </div>
            <div className="flex flex-col justify-between py-1">
              <div className="font-bold">{label}</div>
              <div className="">{slug}</div>
            </div>
          </div>

        </div>
      </div>
    </div>

  )
}
 */

export default TestimonialsSlideshow
